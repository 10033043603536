<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/act_center_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<!-- 项目路演 -->
		<div class="block58"></div>
		<div class="huaxue_new_type_box w1239" v-if="roadShowList.length>0">
			<div class="type_name fontsize38">项目路演</div>
			<div class="type_desc fontsize14">PROJECT ROADSHOW</div>
		</div>
		<div class="huaxue_home_type w1239" v-if="roadShowList.length>0">
			<div class="title fontsize38"></div>
			<div class="typelist">
				<div class="typeobj fontsize22" @click="$util.routerPath('/infoItem',{type:'roadshow'})">查看更多</div>
			</div>
		</div>
		<div class="huaxue_activity_center_box w1252">
			<div class="itemobj" v-for="(item,index) in roadShowList" :key="index" @click="projectDetil(index)">
				<div class="img relative">
					<img :src="item.imgUrl || require('../assets/static/noimg.png')">
				</div>
				<div class="policy_con">
					<div class="title_video clamp2 fontsize16">{{item.title}}</div>
					<div class="timediv fontsize14">
						<img class="icon" src="../assets/static/time_icon.png"/>
						<div class="text clamp">{{item.projectTime || ""}}</div>
					</div>
					<div class="positiondiv fontsize14">
						<img class="icon" src="../assets/static/position_icon.png"/>
						<div class="text clamp2">{{item.projectAddress || ""}}</div>
					</div>
				    <div class="more fontsize14">查看详情</div>
				</div>
			</div>
		</div>
		<!-- 培训学习 -->
		<div class="block58"></div>
		<div class="huaxue_new_type_box w1239" v-if="videoList.length>0">
			<div class="type_name fontsize38">培训学习</div>
			<div class="type_desc fontsize14">TRAINING AND LEARNING</div>
		</div>
		<div class="huaxue_home_type w1239" v-if="videoList.length>0">
			<div class="title fontsize38"></div>
			<div class="typelist">
				<div class="typeobj fontsize22" @click="$util.routerPath('/infoItem',{type:'pxvideo'})">查看更多</div>
			</div>
		</div>
		<div class="huaxue_activity_center_box w1252">
			<div class="itemobj" v-for="(item,index) in videoList" :key="index" @click="clickarticleDetail(item.id)">
				<div class="img relative">
					<img :src="item.imgUrl || require('../assets/static/noimg.png')">
					<img v-if="item.videoUrl" class="play" src="../assets/static/play2.png"/>
				</div>
				<div class="policy_con">
					<div class="title_video clamp2 fontsize16">{{item.title}}</div>
					<div class="timediv fontsize14">
						<div class="text clamp">主讲人：{{item.authorInfo}}</div>
					</div>
					<div class="positiondiv fontsize14">
						<div class="text clamp2">简介：{{item.description}}</div>
					</div>
				    <div class="more fontsize14">查看详情</div>
				</div>
			</div>
		</div>
		<!-- 专题活动 -->
		<div class="block58"></div>
		<div class="huaxue_new_type_box w1239" v-if="specialList.length>0">
			<div class="type_name fontsize38">知产剧场，轻松一刻</div>
			<div class="type_desc fontsize14">INTELLECTUAL PROPERTY THEATER, A MOMENT OF RELAXATION</div>
		</div>
		<div class="huaxue_home_type w1239" v-if="specialList.length>0">
			<div class="title fontsize38"></div>
			<div class="typelist">
				<div class="typeobj fontsize22" @click="$util.routerPath('/infoItem',{type:'special'})">查看更多</div>
			</div>
		</div>
		<div class="huaxue_specialinfo_box w1252">
			<div class="data_item" v-for="(item,index) in specialList" :key="index" @click="clickarticleDetail(item.id)">
				<div class="img">
					<img :src="item.imgUrl || require('../assets/static/noimg.png')">
				</div>
				<div class="data_con">
					<div class="title clamp2 fontsize16">{{item.title}}</div>
					<div class="timer fontsize14">{{item.releaseTime || item.createDate}}</div>
				</div>
			</div>
		</div>
		
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			roadShowList:[],
			videoList:[],
			specialList:[],
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(19).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		this.getxiangmuluyan()
		this.getPeixunshipin()
		this.getZhuantihuodong()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//项目路演跳转
		projectDetil(index){
			var obj = this.roadShowList[index]
			var url = obj.outUrl
			if(obj.outType==1){
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					this.$router.push({
						name: url
					});
				}
			}else{
				this.$router.push({ path: '/infoDetail', query: {uid: obj.id }})
			}
		},
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		//(文章)项目路演
		getxiangmuluyan() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 4,
			};
			this.$http.post('frontEnd/realTime/getxiangmuluyan', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						records.forEach((item,index)=>{
							//item["body"] = _this.$util.filtersText(item.contents,78)
							// if(item.createDate){
							// 	item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
							// }
						})
						_this.roadShowList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//(文章)培训视频
		getPeixunshipin() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 4,
			};
			this.$http.post('frontEnd/realTime/getpeixunxuexi', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						records.forEach((item,index)=>{
							//item["body"] = _this.$util.filtersText(item.contents,78)
							// if(item.createDate){
							// 	item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
							// }
						})
						_this.videoList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//(文章)专题活动
		getZhuantihuodong() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 4,
			};
			this.$http.post('frontEnd/realTime/getzhichanjuchang', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						// records.forEach((item,index)=>{
						// 	item["body"] = _this.$util.filtersText(item.contents,78)
						// 	if(item.createDate){
						// 		item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						// 	}
						// })
						_this.specialList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
